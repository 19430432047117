import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PhoneOutlined } from "@ant-design/icons";

export default (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            phoneNumber
          }
        }
      }
    `
  );

  const linkPhoneNumber = site.siteMetadata.phoneNumber;

  return (
    <div className={props.className}>
      <PhoneOutlined className={` ${props.showIcon ? `inline` : `hidden`} mr-2 align-middle`} />
      <a target="_blank" rel="noreferrer" href={"tel:" + linkPhoneNumber}>
        {linkPhoneNumber}
      </a>
    </div>
  );
};
