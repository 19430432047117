import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MailOutlined } from "@ant-design/icons";

export default (props) => {
  const SUPPORT_KEY = "TCC Support";
  const { site } = useStaticQuery(
    graphql`
      query($key: String) {
        site(siteMetadata: { emailAddresses: { elemMatch: { name: { eq: $key } } } }) {
          siteMetadata {
            emailAddresses {
              name
              configuration {
                address
                text
              }
            }
          }
        }
      }
    `,
    { key: SUPPORT_KEY }
  );

  const linkConfiguration = site.siteMetadata.emailAddresses.find((e) => e.name === SUPPORT_KEY).configuration;

  return (
    <div className={props.className}>
      <MailOutlined className={` ${props.showIcon ? `inline` : `hidden`} mr-2 align-middle`} />
      <a target="_blank" rel="noreferrer" href={"mailto:" + linkConfiguration.address}>
        {linkConfiguration.text}
      </a>
    </div>
  );
};
