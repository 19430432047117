import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            address {
              companyName
              addressLine1
              addressLine2
              state
              zipCode
            }
          }
        }
      }
    `
  );

  const { address } = site.siteMetadata;

  return (
    <ul className={`${props.className} list-none`}>
      <li key={uuidv4()}>
        <p className="py-0">{address.companyName}</p>
      </li>
      <li key={uuidv4()}>
        <p className="py-0">{address.addressLine1}</p>
      </li>
      <li key={uuidv4()}>
        <p className="py-0">{address.addressLine2}</p>
      </li>
      <li key={uuidv4()}>
        <p className="py-0">{address.state}</p>
      </li>
      <li key={uuidv4()}>
        <p className="py-0">{address.zipCode}</p>
      </li>
    </ul>
  );
};
