import React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const { siteUrl } = site.siteMetadata;

  return (
    <Layout pageDescription="Read the legal Terms of Service that govern how we work">
      <PrivacyPolicyContent siteUrl={siteUrl} />
    </Layout>
  );
};
